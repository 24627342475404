import React, { useEffect, useState } from "react";
import PageContainer from "../components/containers/PageContainer";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import Field from "../components/ui/Field";
import CustomSelect from "../components/ui/CustomSelect";
import { id } from "date-fns/locale";
import { formatDate, states } from "../utilities/misc";
import Section from "../components/ui/Section";
import api from "../config/axios";
import CustomTable from "../components/ui/CustomTable";
import Loader from "../components/ui/Loader";
import env from "../config/env";
import { toast } from "react-toastify";
import { Download } from "@mui/icons-material";
const MailType = [
  {
    name: "Priority Mail Service®",
    id: "1",
  },
  {
    name: "Priority Mail Express Service®",
    id: "2",
  },
  {
    name: "Library Mail Service®",
    id: "3",
  },
  {
    name: "International Mail®",
    id: "4",
  },
  {
    name: "First-Class Package Service®",
    id: "5",
  },
  {
    name: "Ground Advantage Package Service®",
    id: "6",
  },
  {
    name: "Other",
    id: "7",
  },
];
const ScanRequest = () => {
  const [type, setType] = useState("");
  const [types, setTypes] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [scansLoading, setScansLoading] = useState(false);
  const [createScanLoading, setCreateScanLoading] = useState(false);
  const [scans, setScans] = useState([]);
  const [address, setAddress] = useState({});
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState(0);
  const [sort, setSort] = useState("des");
  const [limit, setLimit] = useState("10");
  const [search, setSearch] = useState("");
  const [trackings, setTrackings] = useState(0);
  const [finalPrice, setFinalPrice] = useState(0);
  const [isDisabled, setIsDisabled] = useState(false);
  const [From, setFrom] = useState({
    name: "",
    street: "",
    street2: "",
    city: "",
    state: "",
    zip: "",
    country: "US",
  });
  const getScans = async (resetDate = false) => {
    setLoading(true);
    await api
      .get(
        `/scans/read?sort=${sort}&page=${page}&limit=${limit}&search=${search}`
      )
      .then((res) => {
        setScans(res.data.scans);
        setTotalPages(res.data.totalPages);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  };
  const readScanTypes = async () => {
    await api
      .get("/scantype/read")
      .then((res) => {
        setTypes(res.data.scanTypes);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getAddresses();
    getScans();
    readScanTypes();
  }, []);
  const getAddresses = async () => {
    await api
      .get("/address/readAll")
      .then((res) => {
        setAddresses(res.data.addresses);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    setFrom({
      name: address.name,
      street: address.street,
      street2: address.street2,
      city: address.city,
      state: address.state,
      zip: address.zip,
      country: "US",
    });
  }, [address]);

  const getPrice = async () => {
    const data = {
      Type: type,
    };

    await api
      .post("/scans/price", data)
      .then((res) => {
        setFinalPrice(res.data.price);
      })
      .catch((err) => {
        console.log(err);
        setFinalPrice(0);
      });
  };

  useEffect(() => {
    // find the type of label and max weight

    getPrice();
  }, [type]);

  const createOrder = async (e) => {
    e.preventDefault();
    setCreateScanLoading(true);
    setIsDisabled(true);

    const data = {
      price: finalPrice,
      Type: e.target.type.value,
      Volume: Number(trackings),
      trackings: trackings,
      FromName: From.name,
      FromStreet: From.street,
      FromStreet2: From.street2 || "",
      FromCity: From.city,
      FromState: From.state,
      FromZip: From.zip,
      FromCountry: "USA",
    };

    console.log(data);

    await api
      .post("/scans/create", data)
      .then(async (res) => {
        toast.success("Scan created successfully");
        getScans();
        await downloadPdf(res.data?.scan?._id);
        setFrom({
          name: "",
          street: "",
          street2: "",
          city: "",
          state: "",
          zip: "",
          country: "US",
        });
        setTrackings(0);
        setFinalPrice(0);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message);
      })
      .finally(() => {
        setIsDisabled(false);
        setCreateScanLoading(false);
      });
  };
  const downloadPdf = async (id) => {
    await api
      .get(`/scans/download/${id}`, env.downloadConfig)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "scan.pdf");
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => console.log(err.response.data.message));
  };
  return (
    <PageContainer
      title="Create Scan"
      desc="Add the required details and create your SCAN">
      <form onSubmit={createOrder}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={7}>
            <Section title={<>{"Return Address"}</>} mb0 sx={{ mb: 2 }}>
              <Grid container mt={2} spacing={2}>
                <Grid item xs={12}>
                  <CustomSelect
                    label="Address Book Address"
                    value={address._id}
                    onChange={(e) => {
                      setAddress(
                        addresses.find((a) => a._id === e.target.value)
                      );
                    }}
                    options={addresses.map((address) => ({
                      label: address.name,
                      value: address._id,
                    }))}
                  />
                </Grid>
                <Grid item xs={12} container spacing={2}>
                  <Grid item xs={6}>
                    <Stack spacing={2}>
                      <CustomSelect
                        label="Country*"
                        disabled
                        required
                        name={"fromCountry"}
                        value={"US"}
                        options={[
                          {
                            label: "United States",
                            value: "US",
                          },
                        ]}
                      />

                      <Field
                        label="Address 1*"
                        placeholder="Enter Address 1"
                        value={From.street}
                        onChange={(e) =>
                          setFrom({
                            ...From,
                            street: e.target.value,
                          })
                        }
                        required
                      />

                      <Field
                        label="Zip * (auto-fills state and city)"
                        placeholder="Enter Zipcode"
                        required
                        value={From.zip}
                        onChange={(e) => {
                          setFrom({
                            ...From,
                            zip: e.target.value,
                          });
                        }}
                      />
                      <Field
                        label="City*"
                        placeholder="Enter City"
                        required
                        value={From.city}
                        onChange={(e) => {
                          setFrom({
                            ...From,
                            city: e.target.value,
                          });
                        }}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={6}>
                    <Stack spacing={2}>
                      <Field
                        label="Contact Name*"
                        placeholder="Enter contact name"
                        value={From.name}
                        onChange={(e) =>
                          setFrom({ ...From, name: e.target.value })
                        }
                        required
                      />
                      <Field
                        label="Address 2 (Optional)"
                        placeholder="Enter Address 2"
                        value={From.street2}
                        onChange={(e) => {
                          setFrom({
                            ...From,
                            street2: e.target.value,
                          });
                        }}
                      />

                      <CustomSelect
                        label="State*"
                        value={From?.state || ""}
                        onChange={(e) => {
                          setFrom({
                            ...From,
                            state: e.target.value,
                          });
                        }}
                        options={states
                          .concat([
                            {
                              Country: "US",
                              ID: "VI",
                              Name: "U.S. Virgin Islands",
                            },
                            {
                              Country: "US",
                              ID: "GU",
                              Name: "Guam",
                            },
                          ])
                          .filter((state) => state.Country === "US")
                          .map((state) => ({
                            label: state.Name,
                            value: state.ID,
                          }))}
                        required
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Section>
          </Grid>
          <Grid item xs={12} sm={5}>
            <CustomSelect
              sx={{ mb: 2 }}
              label="Choose Type of Mail"
              name="type"
              onChange={(e) => {
                setType(e.target.value);
              }}
              options={types.map((type) => ({
                label: type.type,
                value: type._id,
              }))}
            />
            <Field
              label="Number of Packages"
              placeholder="Enter Numbers of Packages"
              name="volume"
              type="number"
              value={trackings}
              onChange={(e) => setTrackings(e.target.value)}
              sx={{ mb: 15 }}
            />

            <Button
              disabled={!finalPrice || isDisabled}
              fullWidth
              variant="contained"
              type="submit"
              sx={{ mt: 20, fontSize: 14 }}>
              {createScanLoading ? (
                <Loader />
              ) : (
                "Create Scan | $" + finalPrice.toFixed(2)
              )}
            </Button>
          </Grid>
        </Grid>
      </form>

      <Divider
        component="li"
        mb={2}
        style={{ marginBottom: 25, marginTop: 15 }}
      />

      <CustomTable
        fields={[
          "ID",
          "DATE",
          "BARCODE",
          "NO. OF TRACKING",
          "SCAN TYPE",
          "PRICE",
          "ACTION",
        ]}
        loading={scansLoading}
        count={totalPages}
        page={page}
        pagination
        setPage={setPage}>
        {scans?.map((order) => (
          <TableRow>
            <TableCell>{order._id}</TableCell>
            <TableCell>{formatDate(order.createdAt)}</TableCell>
            <TableCell>{order.scancode}</TableCell>
            <TableCell>{order.Volume}</TableCell>
            <TableCell>{order.scanType?.type}</TableCell>
            <TableCell>${Number(order.price).toFixed(2)}</TableCell>
            <TableCell>
              <IconButton
                color="info"
                title="Download Scan"
                onClick={() => {
                  downloadPdf(order._id);
                }}>
                <Download fontSize="small" />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </CustomTable>
    </PageContainer>
  );
};

export default ScanRequest;
