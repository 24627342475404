import { Button, Divider, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useUserContext } from "../App";
import StatusComp from "../components/common/StatusComp";
import LoadingContainer from "../components/containers/LoadingContainer";
import PageContainer from "../components/containers/PageContainer";
import ConfirmDuplicate from "../components/modals/ConfirmDuplicate";
import Section from "../components/ui/Section";
import api from "../config/axios";
import env from "../config/env";
import routes from "../config/routes";
import { copyToClipboard, formatDate } from "../utilities/misc";
import NorthEastIcon from "@mui/icons-material/NorthEast";
import ConfirmRequestRefund from "../components/modals/ConfirmRequestRefund";
import CreateTicket from "../components/modals/CreateTicket";
import Loader from "../components/ui/Loader";

export const track = (type, id) => {
  if (type.toLowerCase().includes("ups")) {
    window.open("https://www.ups.com/track?loc=en_US&requester=ST", "_blank");
  }
  if (type.toLowerCase().includes("usps")) {
    window.open(
      "https://tools.usps.com/go/TrackConfirmAction?tLabels=" + id,
      "_blank"
    );
  }
  if (type.toLowerCase().includes("fedex")) {
    window.open(
      "https://www.fedex.com/fedextrack/?action=track&trackingnumber=" +
        id +
        "&cntry_code=us",
      "_blank"
    );
  }
};

const ViewInterLabel = () => {
  const { id } = useParams();
  const location = useLocation();
  const queryString = location.search;
  const params = new URLSearchParams(queryString);
  const isNew = params.get("new");
  const navigate = useNavigate();
  const [order, setOrder] = useState({});
  const [loading, setLoading] = useState(false);
  const [duplicating, setDuplicating] = useState(false);
  const [duplicateConfirmShow, setDuplicateConfirmShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [requestRefundShow, setRequestRefundShow] = useState(false);

  const getOrder = async () => {
    setLoading(true);
    await api
      .get("/international-order/read/" + id)
      .then((res) => setOrder(res.data.order))
      .catch((err) => toast.error(err.response.data.message))
      .finally(() => setLoading(false));
  };

  const [downloading, setDownloading] = useState(false);
  const [downloading2, setDownloading2] = useState(false);

  const downloadPdf = async () => {
    setDownloading(true);
    await api
      .get(`/international-order/download/${id}`, env.downloadConfig)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "label.pdf");
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => toast.error(err.response.data.message))
      .finally(() => setDownloading(false));
  };

  const openPdf = async () => {
    setDownloading2(true);
    await api
      .get(`/international-order/download/${id}`, env.downloadConfig)
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/pdf" })
        );
        window.open(url);
      })
      .catch((err) => toast.error(err.response.data.message))
      .finally(() => setDownloading2(false));
  };

  const { refresh } = useUserContext();

  const requestRefund = async (message, id) => {
    setLoader(true);

    const params = {
      message,
    };

    await api
      .post("/order/request-refund/" + id, params)
      .then((res) => {
        toast.success(res.data.message);
        setLoader(false);
        setRequestRefundShow(false);
        getOrder();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setLoader(false);
      });
  };

  useEffect(() => {
    getOrder();
    // if order is new refresh data after 5s
    if (isNew) setTimeout(getOrder, 5000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [ticketShow, setTicketShow] = useState(false);

  return (
    <LoadingContainer loading={loading}>
      <PageContainer>
        <Section
          title={isNew ? "Label created successfully" : "Label Details"}
          end={
            <Link to={"/labels"} className="yellow-text">
              <Button>View all labels</Button>
            </Link>
          }
        >
          <Divider sx={{ bgcolor: "rgba(0,0,0,0.2)" }} />
          <Stack
            direction="row"
            spacing={3}
            justifyContent="space-between"
            mt={2}
          >
            <DetailComp label="Type" val={order?.labelType?.name} />
            <DetailComp
              label="Weight"
              val={
                order?.Weight +
                (order?.labelType?.uid?.includes("firstclass") ? " Oz" : " Lbs")
              }
            />

            <DetailComp
              label="Date and Time"
              val={formatDate(order?.createdAt)}
            />
            <DetailComp label="ID" val={order?._id} half />
            {order.tracking ? (
              <DetailComp
                half
                label="Tracking Number"
                val={
                  <Button onClick={() => copyToClipboard(order?.tracking)}>
                    {order?.tracking} <NorthEastIcon sx={{ fontSize: 13 }} />
                  </Button>
                }
              />
            ) : (
              <DetailComp
                half
                label="Tracking Number"
                val={"Tracking not yet available"}
              />
            )}

            <DetailComp
              sx={{ color: "success.main" }}
              label="Amount"
              val={<>${order?.price?.toFixed(2)}</>}
            />
            <DetailComp
              label="Status"
              val={<StatusComp status={order?.status} />}
            />
          </Stack>
          <Divider sx={{ bgcolor: "rgba(0,0,0,0.2)" }} />
          <Typography mt={2}>Package Details</Typography>
          <Stack direction="row" spacing={12} mt={2} mb={2}>
            <DetailComp
              sx={{ mb: 2 }}
              label="Item Description:"
              val={order?.itemDescription || "N/A"}
            />
            <DetailComp
              sx={{ mb: 2 }}
              label="Item Value:"
              val={order?.itemValue || "N/A"}
            />
            <DetailComp
              sx={{ mb: 2 }}
              label="Item Quantity:"
              val={order?.itemQuantity || "N/A"}
            />
            <DetailComp
              sx={{ mb: 2 }}
              label="HS Tariff code:"
              val={order?.HSTariffcode || "N/A"}
            />
            <DetailComp
              sx={{ mb: 2 }}
              label="Dimensions ( inches) H.W.L:"
              val={
                `${order?.height} x ${order?.width} x ${order?.length}` || "N/A"
              }
            />
          </Stack>
          <Divider sx={{ bgcolor: "rgba(0,0,0,0.2)" }} />
          <Typography mt={2}>Sender's Details</Typography>
          <Stack
            direction="row"
            spacing={3}
            justifyContent="space-between"
            mt={1}
            mb={2}
          >
            <DetailComp
              label="Contact Full name"
              val={order?.FromFirstName + " " + order?.FromLastName}
            />
            <DetailComp label="Address Line A" val={order?.FromStreet} />
            <DetailComp label="Address Line B" val={order?.FromStreet2} />
            <DetailComp label="Phone Number" val={order?.FromPhone || "N/A"} />
            <DetailComp label="Email Address" val={order?.FromEmail || "N/A"} />

            <DetailComp label="Country" val={order?.FromCountry} />
            <DetailComp label="City" val={order?.FromCity} />
            <DetailComp label="State" val={order?.FromState} />
            <DetailComp label="Zip" val={order?.FromZip} />
          </Stack>
          <Divider sx={{ bgcolor: "rgba(0,0,0,0.2)" }} />
          <Typography mt={2}>Reciever's Details</Typography>
          <Stack
            direction="row"
            spacing={3}
            justifyContent="space-between"
            mt={1}
            mb={2}
          >
            <DetailComp
              label="Contact name"
              val={order?.ToFirstName + " " + order?.ToLastName}
            />
            <DetailComp label="Address Line A" val={order?.ToStreet} />
            <DetailComp label="Address Line B" val={order?.ToStreet2} />
            <DetailComp label="Phone Number" val={order?.ToPhone || "N/A"} />
            <DetailComp label="Email Address" val={order?.ToEmail || "None"} />
            <DetailComp label="Country" val={order?.ToCountry} />
            <DetailComp label="City" val={order?.ToCity} />
            <DetailComp label="State" val={order?.ToState} />
            <DetailComp label="Zip" val={order?.ToZip} />
          </Stack>
          <Divider sx={{ bgcolor: "rgba(0,0,0,0.2)" }} />
          <Stack direction="row" alignItems={"center"} spacing={2} mt={2}>
            <Typography>Actions</Typography>

            {order?.status === "completed" && (
              <>
                {order.status !== "refundAccepted" && (
                  <>
                    <Button
                      onClick={() => openPdf(order?._id)}
                      variant="outlined"
                      color="primary"
                    >
                      {downloading2 ? <Loader primary /> : "Open"}
                    </Button>
                    <Button
                      onClick={() => downloadPdf(order?._id)}
                      variant="outlined"
                      color="warning"
                    >
                      {downloading ? <Loader primary /> : "Download"}
                    </Button>

                    <Button
                      title="Open ticket"
                      onClick={() => {
                        setTicketShow(true);
                      }}
                      variant="outlined"
                      color="primary"
                    >
                      Open Ticket
                    </Button>
                  </>
                )}
              </>
            )}

            {order?.status === "completed" && (
              <Button
                onClick={() => track(order?.labelType?.name, order?.tracking)}
                variant="outlined"
                color="primary"
              >
                Track
              </Button>
            )}
          </Stack>
        </Section>
      </PageContainer>

      <ConfirmRequestRefund
        open={requestRefundShow}
        onClose={() => setRequestRefundShow(false)}
        action={(e) => requestRefund(e, order._id)}
        loading={loader}
      />
      <CreateTicket
        open={ticketShow}
        onClose={() => setTicketShow(false)}
        orderId={order?._id}
      />
    </LoadingContainer>
  );
};

const DetailComp = ({ label, val, desc, sx }) => (
  <Stack sx={{ fontSize: 15, ...sx }} gap={0.5}>
    <Typography sx={{ fontSize: 14 }} color="text.secondary">
      {label}
    </Typography>
    <Typography fontWeight={500}>{val}</Typography>
    <Typography fontWeight={500}>{desc}</Typography>
  </Stack>
);

export default ViewInterLabel;
