import { useState } from "react"
import ModalContainer from "../containers/ModalContainer"
import api from "../../config/axios"
import { toast } from "react-toastify"
import { Stack, Button, Grid, alpha, useTheme } from "@mui/material"
import Loader from "../ui/Loader"
import Field from "../ui/Field"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"

const CreateTicket = ({ orderId, ...props }) => {
  const [loading, setLoading] = useState(false)
  const [fileUpload, setFileUpload] = useState(null)
  const createTicket = async (e) => {
    e.preventDefault()
    setLoading(true)

    // const params = new FormData();
    // params.append("ticketID", orderId);
    // params.append("message", e.target.message.value);
    // params.append("subject", e.target.subject.value);
    // params.append("attachment", fileUpload);

    await api
      .post("/ticket/create", {
        order: e.target.order.value,
        message: e.target.message.value,
        subject: e.target.subject.value,
      })
      .then(() => {
        toast.success("Ticket created successfully")
        setFileUpload(null)
        e.target.reset()
        props.onClose()
      })
      .catch((err) => toast.error(err.response.data.message))
      .finally(() => setLoading(false))
  }
  const theme = useTheme()
  return (
    <ModalContainer {...props} title="Create Ticket" hideActions>
      <form onSubmit={createTicket}>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} sm={8}>
            <Field
              name="subject"
              label="Title*"
              placeholder="Your Ticket Title here"
              required
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Field
              name="order"
              label="Order ID"
              placeholder="Your Order ID here"
              value={orderId}
              disabled
            />
          </Grid>
        </Grid>
        <Field
          name="message"
          label="Message*"
          placeholder="Ticket message here"
          multiline
          rows={5}
          required
        />
        <Stack
          direction={"column"}
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
          mt={2}
        >
          {/* <Stack
            direction={{ xs: "column", sm: "row", fontSize: 14 }}
            alignItems="center"
          >
            <Button
              type="button"
              variant="outlined"
              component="label"
              sx={{
                mr: 2,
                px: 3,
                bgcolor: alpha(theme.palette.primary.main, 0.1),
              }}
            >
              {fileUpload?.name || "Add Attachment (Upto 2MB)"}

              <input
                accept=".png, .jpeg, .jpg, .pdf"
                type="file"
                id="img"
                hidden
                name="img"
                onChange={(e) => {
                  setFileUpload(e.target.files[0])
                }}
              />
            </Button>

            <span>
              <span style={{ color: "silver" }}>Formats accepted</span> : .png,
              .jpeg, .pdf
            </span>
          </Stack> */}

          <Button
            disabed={loading}
            type="submit"
            variant="contained"
            sx={{ px: 3, gap: 1 }}
            fullWidth
          >
            {loading ? (
              <Loader />
            ) : (
              <>
                Open Ticket
                <ArrowForwardIcon fontSize="small" />
              </>
            )}
          </Button>
        </Stack>
      </form>
    </ModalContainer>
  )
}

export default CreateTicket
