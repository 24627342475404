import {
  Alert,
  Box,
  Button,
  Chip,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import PageContainer from "../components/containers/PageContainer";
import Field from "../components/ui/Field";
import Section from "../components/ui/Section";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import CustomSelect from "../components/ui/CustomSelect";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../config/axios";
import { toast } from "react-toastify";
import { Countries, states } from "../utilities/misc";
import Loader from "../components/ui/Loader";
import OptionCard from "../components/common/OptionCard";
import { Autocomplete } from "@react-google-maps/api";
import { useRef } from "react";
import { useUserContext } from "../App";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import AddAddress from "../components/modals/AddAddress";
import axios from "axios";

const pricingItems = [
  { name: "usps", icon: "courier-icon-01" },
  { name: "ups", icon: "courier-icon-03" },
  { name: "fedex", icon: "courier-icon-02" },
];

const PriceButton = ({ finalPrice, originalPrice, activeType }) => (
  <Button variant="contained">
    <Stack direction="row" spacing={1} alignItems="center">
      {/* <AttachMoneyRoundedIcon /> */}
      <Typography>Price:</Typography>
      {activeType?.discount === 0 ? (
        ""
      ) : (
        <>
          {!!finalPrice && (
            <s
              style={{
                fontSize: 10,
                opacity: 0.5,
              }}
            >
              $ {originalPrice?.toFixed(2) || 0}
            </s>
          )}
          {activeType?.discount && finalPrice && (
            <Chip
              label={`-${activeType?.discount}%`}
              color="success"
              size="small"
              sx={{ color: "#fff" }}
            />
          )}{" "}
        </>
      )}

      <span style={{ fontSize: 18 }}>
        {" "}
        {`$${Number(finalPrice).toFixed(2)}`}
      </span>
    </Stack>
  </Button>
);

const AddressForm = ({
  sender,
  address,
  addresses,
  setAddress,
  data,
  setData,
  activeType,
  cityStateLookup,
  saveAddressCheck,
  setSaveAddressCheck,
  openAddModal,
  selectedCourier,
}) => {
  const [collapsed, setCollapsed] = useState(false);

  // for google autofill
  const [showAutocomplete, setShowAutocomplete] = useState(false);
  const inputRef = useRef();
  const handlePlaceChanged = () => {
    const place = inputRef.current.getPlace();
    if (place) {
      let city = "";
      let state = "";
      let zip = "";

      place?.address_components?.forEach((p) => {
        if (p?.types[0] === "locality") city = p?.short_name || "";
        if (p?.types[0] === "administrative_area_level_1")
          state = p?.short_name || "";
        if (p?.types[0] === "postal_code") zip = p?.short_name || "";
      });

      setData({
        ...data,
        street: place.formatted_address.split(",")[0],
        street2:
          place.formatted_address.split(",").length > 4
            ? place.formatted_address.split(",")[1]
            : "",
        city,
        state,
        zip,
      });
    }
  };

  //for phone number auto-generation
  const generatePhoneNo = () => {
    const num = Math.floor(9000000000 + Math.random() * 1000000000);
    setData({
      ...data,
      phone: num.toString(),
    });
  };
  return (
    <Section
      title={
        <>
          {sender ? "Sender’s Details" : "Recipient’s Details"}

          <Typography display={"inline-block"} sx={{ ml: 2 }} fontWeight={500}>
            {["Name", "City", "State", "Zip"].every(
              (x) => data[x.toLowerCase()]
            ) ? (
              <>
                {["Name", "City", "State", "Zip"].map((info, i) =>
                  data[info.toLowerCase()] ? (
                    data[info.toLowerCase()] + (i !== 3 ? ", " : "")
                  ) : (
                    <Typography display={"inline"} color="error.main">
                      {info} missing{i !== 3 ? ", " : ""}
                    </Typography>
                  )
                )}
              </>
            ) : (
              <Chip label="DETAILS REQUIRED" sx={{ borderRadius: 1 }} />
            )}
          </Typography>
        </>
      }
      end={
        <Stack direction="row" spacing={1}>
          {/* <Button
            onClick={openAddModal}
            sx={{ fontWeight: 600, fontSize: 14, gap: 0.5 }}
          >
            <AddRoundedIcon fontSize="small" /> Add New Address
          </Button> */}
          <IconButton type="button" onClick={() => setCollapsed(!collapsed)}>
            {collapsed ? (
              <KeyboardArrowDownRoundedIcon />
            ) : (
              <KeyboardArrowUpRoundedIcon />
            )}
          </IconButton>
        </Stack>
      }
      mb0
      sx={{ mb: 2 }}
    >
      {!collapsed && (
        <Grid container mt={2} spacing={2}>
          {/* <Grid item xs={12}>
            <CustomSelect
              label="Address Book Address"
              value={address._id}
              onChange={(e) => {
                setAddress(addresses.find((a) => a._id === e.target.value));
              }}
              options={addresses.map((address) => ({
                label: address.name,
                value: address._id,
              }))}
            />
          </Grid> */}

          <Grid item xs={12} container spacing={2}>
            {/* Name */}
            <Grid item xs={12}>
              <Stack spacing={2} direction={"row"}>
                <Field
                  label="First Name*"
                  placeholder="Enter first name"
                  value={data.name}
                  onChange={(e) =>
                    setData({ ...data, firstname: e.target.value })
                  }
                  required
                />
                <Field
                  label="Last Name*"
                  placeholder="Enter last name"
                  value={data.name}
                  onChange={(e) =>
                    setData({ ...data, lastname: e.target.value })
                  }
                  required
                />
              </Stack>
            </Grid>
            {/* phone email */}
            <Grid item xs={12}>
              <Stack spacing={2} direction={"row"}>
                <Field
                  label={<Box sx={{ mb: -0.2 }}>Phone Number*</Box>}
                  placeholder="Enter phone number"
                  value={data.phone}
                  onChange={(e) => {
                    setData({
                      ...data,
                      phone: e.target.value,
                    });
                  }}
                />
                <Field
                  label={<Box sx={{ mb: -0.2 }}>Email*</Box>}
                  placeholder="Enter email address"
                  value={data.email}
                  onChange={(e) => {
                    setData({
                      ...data,
                      email: e.target.value,
                    });
                  }}
                />
              </Stack>
            </Grid>
            {/* Street  */}
            <Grid item xs={12}>
              <Stack spacing={2} direction={"row"}>
                <Field
                  label="Road Name*"
                  placeholder="Enter Road Name"
                  value={data.street}
                  onChange={(e) =>
                    setData({
                      ...data,
                      street: e.target.value,
                    })
                  }
                  required
                />

                <Field
                  label="Road Number "
                  placeholder="Enter Road Number"
                  value={data.street2}
                  onChange={(e) => {
                    setData({
                      ...data,
                      street2: e.target.value,
                    });
                  }}
                />
              </Stack>
            </Grid>
            {/* city zip */}
            <Grid item xs={12}>
              <Stack spacing={2} direction={"row"}>
                <Field
                  label="City*"
                  placeholder="Enter City"
                  required
                  value={data.city}
                  onChange={(e) => {
                    setData({
                      ...data,
                      city: e.target.value,
                    });
                  }}
                />
                <Field
                  label="ZIP Code™ * "
                  placeholder="Enter ZIP Code™"
                  required
                  value={data.zip}
                  onChange={(e) => {
                    setData({
                      ...data,
                      zip: e.target.value,
                    });
                  }}
                />
              </Stack>
            </Grid>
            {/* Country State */}
            <Grid item xs={12}>
              <Stack spacing={2} direction={"row"}>
                <CustomSelect
                  label="Country*"
                  name={(sender ? "from" : "to") + "Country"}
                  onChange={(e) => {
                    setData({
                      ...data,
                      country: e.target.value,
                    });
                  }}
                  value={data?.country || ""}
                  options={Countries.map((con) => ({
                    label: con,
                    value: con,
                  }))}
                  required
                />
                {data.country !== "United States" ? (
                  <Field
                    label={<Box sx={{ mb: -0.2 }}>State/Province*</Box>}
                    placeholder="Enter State/Province"
                    value={data.state}
                    onChange={(e) => {
                      setData({
                        ...data,
                        state: e.target.value,
                      });
                    }}
                  />
                ) : (
                  <CustomSelect
                    label="State/Province*"
                    value={data?.state || ""}
                    onChange={(e) => {
                      setData({
                        ...data,
                        state: e.target.value,
                      });
                    }}
                    options={states
                      .filter((state) => state.Country === "US")
                      .map((state) => ({
                        label: state.Name,
                        value: state.ID,
                      }))}
                    required
                  />
                )}
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Section>
  );
};

const USPSInt = () => {
  const [selectedCourier, setSelectedCourier] = useState(pricingItems[0].name);
  const navigate = useNavigate();
  const [addresses, setAddresses] = useState([]);
  const [address, setAddress] = useState({});
  const [address1, setAddress1] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);
  const [finalPrice, setFinalPrice] = useState(0);
  const [Type_id, setType_id] = useState("");
  const [Weight, setWeight] = useState(0);
  const [types, setTypes] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [customItems, setCustomItems] = useState([
    {
      name: "",
      price: 0,
      quantity: 0,
    },
  ]);
  const [originalPrice, setOriginalPrice] = useState(0);
  const [activeType, setActiveType] = useState({});
  const [From, setFrom] = useState({
    firstname: "",
    lastname: "",
    street: "",
    street2: "",
    city: "",
    state: "",
    zip: "",
    company: "",
    country: "",
    email: "",
    phone: "",
  });
  const [To, setTo] = useState({
    firstname: "",
    lastname: "",
    street: "",
    street2: "",
    city: "",
    state: "",
    zip: "",
    company: "",
    country: "",
    w5ye: "",
    phone: "",
  });

  const [addModal, setAddModal] = useState(false);

  const openAddModal = () => setAddModal(true);

  const readLabelTypes = async () => {
    await api
      .get("/international-labeltype/read")
      .then((res) => {
        setTypes(res.data.labelTypes);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    readLabelTypes();
  }, []);

  useEffect(() => {
    const discountedPrice = activeType.discount / 100;
    const negativeDiscount = 1 - discountedPrice;
    const newPrice = finalPrice / negativeDiscount;
    setOriginalPrice(parseFloat(newPrice));
  }, [setFinalPrice, finalPrice, activeType]);

  const getPrice = async () => {
    const data = {
      Type: Type_id,
      Weight,
    };

    await api
      .post("/international-order/price", data)
      .then((res) => {
        setFinalPrice(res.data.price);
      })
      .catch((err) => {
        console.log(err);
        setFinalPrice(0);
      });
  };

  useEffect(() => {
    // find the type of label and max weight

    getPrice();
  }, [Type_id, Weight]);

  // on change custom items
  useEffect(() => {
    // add all customItems price
    let totalPrice = 0;
    // eslint-disable-next-line no-unused-vars
    let totalQuant = 0;
    customItems.forEach((item) => {
      totalPrice += parseInt(item.price);
      totalQuant += parseInt(item.quantity);
    });

    setTotalPrice(totalPrice);
  }, [customItems]);

  const { refresh } = useUserContext();

  const createOrder = async (e) => {
    e.preventDefault();

    if (
      parseFloat(e.target.Weight.value) < 0.0 ||
      parseFloat(e.target.Weight.value) > activeType?.maxWeight
    ) {
      toast.error("Invalid Weight");
      return;
    }

    setIsDisabled(true);

    const data = {
      price: finalPrice,
      Type: e.target.type.value,
      Weight: e.target.Weight.value,
      ToFirstName: To.firstname,
      ToLastName: To.lastname,
      ToStreet: To.street,
      ToStreet2: To.street2 || "",
      ToCity: To.city,
      ToState: To.state,
      ToZip: To.zip,
      ToCompany: To.company || "",
      ToCountry: To.country,
      ToPhone: To.phone,
      ToEmail: To.email,

      FromFirstName: From.firstname,
      FromLastName: From.lastname,
      FromStreet: From.street,
      FromStreet2: From.street2 || "",
      FromCity: From.city,
      FromState: From.state,
      FromZip: From.zip,
      FromCompany: From.company || "",
      FromCountry: From.country,
      FromPhone: From.phone,
      FromEmail: From.email,

      height: e.target?.height?.value || 0,
      width: e.target?.width?.value || 0,
      length: e.target?.length?.value || 0,
      
      itemDescription: e.target.description?.value || "",
      itemValue: e.target.itemvalue?.value || "",
      itemQuantity: e.target.itemquantity?.value || "",
      HSTariffcode: e.target.hstariff?.value || "",
    };

    console.log(data);

    await api
      .post("/international-order/create", data)
      .then((res) => {
        toast.success("Order created successfully");
        refresh();
        if (saveFromAddress) saveToAddressBook(From);
        if (saveToAddress) saveToAddressBook(To);
        setIsDisabled(false);
        navigate("/international-label/" + res.data?.order?._id + "?new=true");
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message);
        setIsDisabled(false);
      });
  };

  //code for state city auto fill from zip
  const cityStateLookup = async (zip, type = "from") => {
    if (zip.length !== 5) return;

    await axios
      .get(`https://api.zippopotam.us/us/${zip.replaceAll("-", "")}`)
      .then((res) => {
        if (res.data) {
          if (type === "from")
            setFrom({
              ...From,
              city: res.data.places[0]["place name"],
              state: res.data.places[0]["state abbreviation"],
              zip: zip,
            });
          else
            setTo({
              ...To,
              city: res.data.places[0]["place name"],
              state: res.data.places[0]["state abbreviation"],
              zip: zip,
            });
        }
      })
      .catch((err) => console.log(err));
  };

  // code for save address
  const [saveFromAddress, setSaveFromAddress] = useState(false);
  const [saveToAddress, setSaveToAddress] = useState(false);

  const saveToAddressBook = async (data) => {
    const { company: _, ...body } = data;
    await api
      .post("/address/create", body)
      .then((res) => toast.success(res.data.message))
      .catch((err) => toast.error(err.response.data.message));
  };

  console.log(selectedCourier);

  return (
    <PageContainer
      title="Create USPS International Label"
      desc="Add the required details and get your label"
      end={
        <PriceButton
          finalPrice={finalPrice}
          originalPrice={originalPrice}
          activeType={activeType}
        />
      }
    >
      {/* <LoadScript
        googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
        libraries={["places"]}
      > */}
      <form onSubmit={createOrder}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={7}>
            {/* <Section sx={{ mb: 2 }}>
              <FormControl>
                <FormLabel sx={{ fontWeight: 600, mb: 2 }}>
                  Select Courier
                </FormLabel>
                <Grid container gap={2} mb={2}>
                  {pricingItems.map((p) => (
                    <OptionCard
                      imgSrc={
                        "/assets/images/" +
                        p.name +
                        (theme.palette.mode === "dark" ? "2" : "") +
                        ".svg"
                      }
                      name={p.name}
                      active={selectedCourier === p.name}
                      activate={() => setSelectedCourier(p.name)}
                    />
                  ))}
                </Grid>
              </FormControl>
            </Section> */}
            <AddressForm
              sender
              address={address}
              addresses={addresses}
              setAddress={setAddress}
              data={From}
              setData={setFrom}
              activeType={activeType}
              cityStateLookup={cityStateLookup}
              saveAddressCheck={saveFromAddress}
              setSaveAddressCheck={setSaveFromAddress}
              openAddModal={openAddModal}
              selectedCourier={selectedCourier}
            />
            <AddressForm
              address={address1}
              addresses={addresses}
              setAddress={setAddress1}
              data={To}
              setData={setTo}
              activeType={activeType}
              cityStateLookup={cityStateLookup}
              saveAddressCheck={saveToAddress}
              setSaveAddressCheck={setSaveToAddress}
              openAddModal={openAddModal}
              selectedCourier={selectedCourier}
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <Section title="Label details" sx={{ mb: 2 }}>
              <CustomSelect
                sx={{ mb: 2 }}
                label="Choose carrier"
                name="type"
                onChange={(e) => {
                  setType_id(e.target.value);
                  setActiveType(
                    types.find((type) => {
                      return type._id === e.target.value;
                    })
                  );
                }}
                options={types
                  .filter((type) =>
                    type.name.toLowerCase().includes(selectedCourier)
                  )
                  .map((type) => ({
                    label: type.name,
                    value: type._id,
                  }))}
              />

              <Field
                sx={{ mb: 2 }}
                label="Package weight*"
                name="Weight"
                placeholder={`Package Weight${
                  activeType?.maxWeight
                    ? " (" +
                      activeType?.maxWeight +
                      (activeType?.uid?.includes("firstclass")
                        ? " Oz"
                        : " Lbs") +
                      " Max)"
                    : ""
                }*`}
                type="text"
                pattern="[0-9]+([.][0-9]+)?"
                step="any"
                onChange={(e) => setWeight(e.target.value)}
              />
            </Section>
            <Section title="Package Details" sx={{ mb: 2 }}>
              <Stack spacing={2}>
                <FormControl fullWidth>
                  <FormLabel sx={{ fontWeight: 500, mb: 0.6 }}>
                    Dimensions ( inches)*
                  </FormLabel>
                  <Stack direction="row" spacing={2}>
                    <Field placeholder="L" type="number" name="length" />
                    <Field placeholder="W" type="number" name="width" />
                    <Field placeholder="H" type="number" name="height" />
                  </Stack>
                </FormControl>
                <Field
                  label="Item description"
                  placeholder="Enter ttem description"
                  name="description"
                  multiline
                  rows={4}
                />
                <Field
                  name="itemvalue"
                  label="Item value"
                  placeholder="Enter Item value"
                />
                <Field
                  name="itemquantity"
                  label="Item quantity"
                  type="number"
                  placeholder="Enter Item quantity"
                />
                <Field
                  sx={{ mb: 2 }}
                  label="HS Tariff code ( if available)"
                  placeholder="HS Tariff code"
                  name="hstariff"
                />
              </Stack>
            </Section>

            <Stack
              justifyContent="space-between"
              alignItems={"center"}
              spacing={2}
            >
              {/* <PriceButton
                  finalPrice={finalPrice}
                  originalPrice={originalPrice}
                  activeType={activeType}
                />{" "} */}
              <Button
                fullWidth
                disabled={!finalPrice || isDisabled}
                type="submit"
                variant="contained"
                sx={{ px: 5 }}
                size="large"
              >
                {isDisabled ? (
                  <Loader />
                ) : (
                  "Create Label | $" + finalPrice.toFixed(2)
                )}
              </Button>
              <Typography variant="body2" color="text.secondary">
                Your purchase would be deducted from your Balance.
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </form>
      {/* </LoadScript> */}
    </PageContainer>
  );
};

export default USPSInt;
