import { alpha, Button, Grid, Stack, useTheme } from "@mui/material"
import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { useUserContext } from "../App"
import PageContainer from "../components/containers/PageContainer"
import Field, { PasswordField } from "../components/ui/Field"
import Loader from "../components/ui/Loader"
import Section from "../components/ui/Section"
import api from "../config/axios"
import { formatDate } from "../utilities/misc"
import TelegramLoginButton from "react-telegram-login"
import axios from "axios"
import { useNavigate, useNavigation, useSearchParams } from "react-router-dom"

const ChangePass = () => {
  const [loading, setLoading] = useState(false)
  const [loading2, setLoading2] = useState(false)
  const [telegramData, setTelegramData] = useState({})
  const [searchParams, setSearchParams] = useSearchParams()
  const [contact, setContact] = useState({})
  const { user, refresh } = useUserContext()
  const navigate = useNavigate()
  const [newData, setNewData] = useState({
    email: "",
    username: "",
  })
  useEffect(() => {
    getContact()
  }, [])

  const getContact = () => {
    api.get("/admin-settings/contact").then((res) => {
      console.log(res.data)
      setContact(res.data.contact)
    })
  }
  console.log(contact)

  const handleChange = (e) => {
    const { name, value } = e.target
    setNewData({
      ...newData,
      [name]: value,
    })
  }

  useEffect(() => {
    setNewData({
      email: user.email,
      username: user.username,
    })
  }, [user])

  const changePassword = async (e) => {
    e.preventDefault()
    setLoading(true)
    if (e.target.newPassword.value !== e.target.password2.value) {
      toast.error("Passwords do not match")
      setLoading(false)
      return
    }

    const data = {
      oldPassword: e.target.oldPassword.value,
      newPassword: e.target.newPassword.value,
    }

    await api
      .put("/auth/update-password", data)
      .then((res) => {
        toast.success(res.data.message)
      })
      .catch((err) => {
        toast.error(err.response.data.message)
      })
      .finally(() => setLoading(false))
  }

  const update = async (e) => {
    setLoading2(true)
    await api
      .put("/auth/updateProfile", newData)
      .then((res) => {
        toast.success(res.data.message)
        refresh()
      })
      .catch((err) => {
        toast.error(err.response.data.message)
      })
      .finally(() => setLoading2(false))
  }

  const theme = useTheme()

  const handleTelegramResponse = (response) => {
    if (response !== null || response !== {}) {
      setTelegramData(response)
      connectToTelegram(response)
    }
  }

  const connectToTelegram = async (response) => {
    const data = {
      telegramID: response.id,
      telegramName: response.username,
    }
    await api
      .post("/user/connectToTelegram", data)
      .then((res) => {
        toast.success(res.data.message)
        window.location.href = "https://t.me/SHIPDIRECTIO"
      })
      .catch((err) => {
        toast.success(err.response.data.message)
        console.log(err)
      })
  }

  const code = searchParams.get("code")

  useEffect(() => {
    callBackDiscord()
  }, [code])

  const callBackDiscord = async () => {
    const params = {
      code,
    }
    if (code) {
      await api
        .post("/auth/discord", params)
        .then((res) => {
          toast.success("Discord Connection Success")

          window.location.href = "https://discord.com/invite/labels"
        })
        .catch((err) => {
          toast.error(err.response.data.message)
        })
    }
  }

  const DiscordLogin = (e) => {
    e.preventDefault()
    window.location.href =
      "https://discord.com/api/oauth2/authorize?client_id=1087049001699913808&redirect_uri=https%3A%2F%2Fshipdirect.io%2Fprofile&response_type=code&scope=identify"
  }

  const disconnectFromTelegram = async () => {
    await api
      .post("/user/disonnectFromTelegram")
      .then((res) => {
        toast.success(res.data.message)
        refresh()
      })
      .catch((err) => {
        toast.success(err.response.data.message)
        console.log(err)
      })
  }

  const disconnectFromDiscord = async () => {
    await api
      .post("/user/disonnectFromDiscord")
      .then((res) => {
        toast.success(res.data.message)
        refresh()
      })
      .catch((err) => {
        toast.success(err.response.data.message)
        console.log(err)
      })
  }
  return (
    <PageContainer
      title="My Profile"
      desc="Edit all your profile related settings here"
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Section title="Profile Details" sx={{ mb: 2 }}>
            <Stack spacing={1} mb={2}>
              <Field disabled value={user.ID} label="User ID" />
              <Field
                name="username"
                value={newData?.username}
                onChange={handleChange}
                label="Username"
              />
              <Field
                name="email"
                value={newData?.email}
                label="Email Address"
                onChange={handleChange}
              />
              <Field
                disabled
                value={user.role === "user" ? "Regular User" : "Admin"}
                label="Role"
              />

              {user.telegramUser && (
                <Field
                  disabled
                  value={"#" + user.telegramName}
                  label="Telegram Username"
                />
              )}
              {user.discordUser && (
                <Field
                  disabled
                  value={"#" + user?.discord?.username}
                  label="Discord Username"
                />
              )}
              <Field
                disabled
                value={formatDate(user?.createdAt)}
                label="Joined"
              />
              <Field disabled value={"None"} label="Referrer" />
            </Stack>
            <Button
              onClick={update}
              fullWidth
              variant="outlined"
              sx={{ bgcolor: alpha(theme.palette.primary.main, 0.1) }}
            >
              {loading2 ? <Loader primary /> : "Update Settings"}
            </Button>
          </Section>
          {/* <Stack direction="row" spacing={1}>
            {!user.discordUser ? (
              <Button
                fullWidth
                className="mb-2"
                variant="contained"
                sx={{ gap: 1, bgcolor: "#5865f2" }}
                onClick={(e) => DiscordLogin(e)}
              >
                <img
                  src="/assets/images/discord-icon.svg"
                  alt="discord"
                  style={{ width: "1.2rem" }}
                />
                Connect your Discord
              </Button>
            ) : (
              <Button
                fullWidth
                className="mb-2"
                variant="contained"
                sx={{ gap: 1, bgcolor: "#5865f2" }}
                onClick={() => disconnectFromDiscord()}
              >
                <img
                  src="/assets/images/discord-icon.svg"
                  alt="discord"
                  style={{ width: "1.2rem" }}
                />
                Disconnect your Discord
              </Button>
            )}
          </Stack> */}
          {/* <Stack direction="row" spacing={1} className="w-100">
            {!user.telegramUser ? (
              <TelegramLoginButton
                dataOnauth={handleTelegramResponse}
                botName="shipdirectiobot"
                usePic={false}
                cornerRadius={5}
                buttonSize="large"
                className="w-100"
              />
            ) : (
              <Button
                fullWidth
                className="mb-2"
                variant="contained"
                sx={{ gap: 1, bgcolor: "#29a9eb" }}
                onClick={() => disconnectFromTelegram()}
              >
                <img
                  src="/assets/images/Telegram1.svg"
                  alt="discord"
                  style={{ width: "1.2rem" }}
                />
                Disconnect your Telegram
              </Button>
            )}
          </Stack> */}
        </Grid>
        <Grid item xs={12} sm={4}>
          <Section title="Reset Password">
            <form onSubmit={changePassword}>
              <Stack spacing={1} mb={2}>
                <PasswordField
                  label="Current Password"
                  name="oldPassword"
                  placeholder="Your existing password"
                />
                <PasswordField
                  name="newPassword"
                  label="New Password"
                  placeholder="Enter your new password"
                />
                <PasswordField
                  name="password2"
                  label="Confirm Password"
                  placeholder="Confirm your new password"
                />
              </Stack>
              <Button
                fullWidth
                type="submit"
                variant="outlined"
                sx={{ bgcolor: alpha(theme.palette.primary.main, 0.1) }}
              >
                {loading ? <Loader primary /> : "Reset Password"}
              </Button>
            </form>
          </Section>
        </Grid>
      </Grid>
    </PageContainer>
  )
}

export default ChangePass
