var hostname = window.location.href;
const env = {
  BASE_API_URL: hostname.includes("localhost")
    ? "http://localhost:7400"
    : "https://api.labelemporium.net",
  RECAPTCHA_KEY: "6Lc73egjAAAAAJQWUEdKVehuoduGEuZpC9Y37ehN",
  downloadConfig: {
    responseType: "blob",
  },
};

export default env;
