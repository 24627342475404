import { Chip } from "@mui/material";
import React from "react";
import { statusInvoiveMap, statusMap } from "../../utilities/misc";

const StatusComp = ({ status }) => {
  return (
    <Chip
      label={statusMap[status]?.text}
      color={statusMap[status]?.color.split(".")[0] || "error"}
      sx={{ borderRadius: 0.5, color: "#fff" }}
      size="small"
    />
  );
};

export default StatusComp;
export const StatusInvoiceComp = ({ status }) => {
  return (
    <Chip
      label={statusInvoiveMap[status]?.text}
      color={statusInvoiveMap[status]?.color.split(".")[0] || "error"}
      sx={{ borderRadius: 0.5, color: "#fff" }}
      size="small"
    />
  );
};
