import {
  FormControl,
  FormLabel,
  InputAdornment,
  IconButton,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const Field = ({ label, wAuto, ...props }) => {
  return (
    <FormControl fullWidth={!wAuto}>
      {label && (
        <FormLabel  sx={{ fontWeight: 600, mb: 0.6 }}>{label}</FormLabel>
      )}
      <TextField {...props} size="small" />
    </FormControl>
  );
};

const CreditCardField = ({ label, wAuto, ...props }) => {
  const handleInputChange = (e) => {
    // Remove any non-digit characters from the input
    const inputNum = e.target.value.replace(/\D/g, "");

    // Use regex to add a space after every 4 digits
    const formattedNum = inputNum.replace(/(\d{4})(?=\d)/g, "$1 ");

    // Ensure the total number of digits is limited to 16
    e.target.value = formattedNum.trim().slice(0, 19);
  };

  return (
    <FormControl fullWidth={!wAuto}>
      {label && (
        <FormLabel sx={{ fontWeight: 600, mb: 0.6 }}>{label}</FormLabel>
      )}
      <TextField {...props} size="small" onChange={handleInputChange} />
    </FormControl>
  );
};

const ExpiryDateField = ({ label, wAuto, ...props }) => {
  const handleInputChange = (e) => {
    // Remove any non-digit characters from the input
    const inputDate = e.target.value.replace(/\D/g, "");

    // Use regex to add a slash after the first 2 digits
    let formattedDate = inputDate.replace(/(\d{2})(?=\d)/g, "$1/");

    // Ensure the total number of digits is limited to 5
    formattedDate = formattedDate.slice(0, 5);

    // Set the formatted value back to the input
    e.target.value = formattedDate;
  };

  return (
    <FormControl fullWidth={!wAuto}>
      {label && (
        <FormLabel sx={{ fontWeight: 600, mb: 0.6 }}>{label}</FormLabel>
      )}
      <TextField {...props} size="small" onChange={handleInputChange} />
    </FormControl>
  );
};

const CVVField = ({ label, wAuto, ...props }) => {
  const handleInputChange = (e) => {
    // Remove any non-digit characters from the input
    const inputCVV = e.target.value.replace(/\D/g, "");

    // Ensure the total number of digits is limited to 4
    e.target.value = inputCVV.slice(0, 4);
  };

  return (
    <FormControl fullWidth={!wAuto}>
      {label && (
        <FormLabel sx={{ fontWeight: 600, mb: 0.6 }}>{label}</FormLabel>
      )}
      <TextField
        {...props}
        size="small"
        onChange={handleInputChange}
        placeholder="Enter CVV"
      />
    </FormControl>
  );
};
export { CreditCardField, ExpiryDateField, CVVField };

const PasswordField = ({ label, ...props }) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <FormControl>
      <FormLabel sx={{ fontWeight: 600, mb: 0.6 }}>{label}</FormLabel>
      <TextField
        size="small"
        type={showPassword ? "text" : "password"}
        {...props}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                type="button"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </FormControl>
  );
};
export { PasswordField };

export default Field;
