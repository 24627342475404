import { Grid, Paper, Typography, useTheme } from "@mui/material";
import Field, { PasswordField } from "../../components/ui/Field";
import AuthContainer from "../../components/containers/AuthContainer";
import AuthSidePic from "../../components/common/AuthSidePic";
import ReCAPTCHA from "react-google-recaptcha";
import { useEffect, useRef, useState } from "react";
import api from "../../config/axios";
import env from "../../config/env";
import { toast } from "react-toastify";
import { Link, useSearchParams } from "react-router-dom";
import routes from "../../config/routes";

const Register = () => {
  const [loading, setLoading] = useState(false);
  const recaptchaRef = useRef(null);
  const theme = useTheme();

  const [searchParams, setSearchParams] = useSearchParams();

  const sourceParam = searchParams.get("source");

  useEffect(() => {
    const isCountIncremented = localStorage.getItem("countIncremented");
    console.log(isCountIncremented);
    console.log(sourceParam);
    if (sourceParam && !isCountIncremented) {
      incrementRegistrationCount();
      localStorage.setItem("countIncremented", "true");
    }
  }, [sourceParam]);
  const incrementRegistrationCount = async () => {
    console.log("registrationCount: " + window.registrationCount);

    try {
      await api.post("/marketing/count", {});
    } catch (error) {
      console.log("error: " + error);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const captchaToken = await recaptchaRef.current.executeAsync();
    recaptchaRef.current.reset();
    const registrationSource = searchParams.get("source") || null;
    const data = {
      username: e.target.name.value,
      email: e.target.email.value,
      password: e.target.password.value,
      captchaToken,
      registrationSource,
    };

    await api
      .post("/auth/register", data)
      .then((res) => {
        toast.success(res.data.message);
        localStorage.setItem("email", data.email);
        setTimeout(() => {
          window.location.href = "/verify";
        }, 1000);
        localStorage.removeItem("countIncremented");
      })
      .catch((err) => toast.error(err.response.data.message))
      .finally(() => setLoading(false));
  };
  return (
    <AuthContainer
      title={"Welcome to LabelEmporium"}
      subtitle="Get access to the most reliable label providers on the internet "
      submitText="Create Account"
      hideColorToggle
      submit={handleSubmit}
      loading={loading}
      bottomContent={
        <>
          <Typography color="text.secondary" textAlign={"center"}>
            By signing up you agree to our and{" "}
            <Link to="/terms" style={{ textDecoration: "underline" }}>
              Terms of Service{" "}
            </Link>
            and{" "}
            <Link to="/terms" style={{ textDecoration: "underline" }}>
              Privacy Policy
            </Link>
          </Typography>
          <Paper
            elevation={0}
            sx={{
              textAlign: "center",
              py: 2,
              fontWeight: 500,
            }}>
            Already have an account?{" "}
            <Link
              to={routes.LOGIN}
              style={{ color: theme.palette.primary.main }}>
              Log In
            </Link>
          </Paper>{" "}
        </>
      }>
      <Field
        label="Username"
        name="name"
        placeholder="Create a username"
        required
      />
      <Field
        label="Email Address"
        name="email"
        placeholder="Enter your email address"
        type="email"
        required
      />
      <PasswordField
        label="Password"
        placeholder="Set a Strong Password"
        name="password"
        required
      />
      <PasswordField
        label="Confirm Password"
        placeholder="Confirm your password"
      />
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey={env.RECAPTCHA_KEY}
        size="invisible"
      />
    </AuthContainer>
  );
};

export default Register;
