import {
  Alert,
  Box,
  Button,
  Chip,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import PageContainer from "../components/containers/PageContainer";
import Field from "../components/ui/Field";
import Section from "../components/ui/Section";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import CustomSelect from "../components/ui/CustomSelect";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../config/axios";
import { toast } from "react-toastify";
import { states } from "../utilities/misc";
import Loader from "../components/ui/Loader";
import OptionCard from "../components/common/OptionCard";
import { Autocomplete } from "@react-google-maps/api";
import { useRef } from "react";
import { useUserContext } from "../App";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import AddAddress from "../components/modals/AddAddress";
import axios from "axios";
import env from "../config/env";
import LinearBuffer from "../components/common/OrderLoader";

const pricingItems = [
  { name: "usps", icon: "courier-icon-01" },
  { name: "ups", icon: "courier-icon-03" },
  { name: "fedex", icon: "courier-icon-02" },
];

const extraStates = [
  {
    ID: "AA",
    Name: "Armed Forces of the Americas",
    Country: "US",
  },
  {
    ID: "AE",
    Country: "US",
    Name: "Armed Forces of Europe",
  },
  {
    ID: "AP",
    Country: "US",
    Name: "Armed Forces of the Pacific",
  },
];
const PriceButton = ({ finalPrice, originalPrice, activeType }) => (
  <Button variant="contained">
    <Stack direction="row" spacing={1} alignItems="center">
      {/* <AttachMoneyRoundedIcon /> */}
      <Typography>Price:</Typography>
      {activeType?.discount === 0 ? (
        ""
      ) : (
        <>
          {!!finalPrice && (
            <s
              style={{
                fontSize: 10,
                opacity: 0.5,
              }}>
              $ {originalPrice?.toFixed(2) || 0}
            </s>
          )}
          {activeType?.discount && finalPrice && (
            <Chip
              label={`-${activeType?.discount}%`}
              color="success"
              size="small"
              sx={{ color: "#fff" }}
            />
          )}{" "}
        </>
      )}

      <span style={{ fontSize: 18 }}>
        {" "}
        {`$${Number(finalPrice).toFixed(2)}`}
      </span>
    </Stack>
  </Button>
);

const AddressForm = ({
  sender,
  address,
  addresses,
  setAddress,
  data,
  setData,
  activeType,
  cityStateLookup,
  saveAddressCheck,
  setSaveAddressCheck,
  openAddModal,
  selectedCourier,
}) => {
  const [pasteAddress, setPasteAddress] = useState("");
  const [collapsed, setCollapsed] = useState(false);
  const [showPaste, setShowPaste] = useState(false);

  // for google autofill
  const [showAutocomplete, setShowAutocomplete] = useState(false);
  const inputRef = useRef();
  const handlePlaceChanged = () => {
    const place = inputRef.current.getPlace();
    if (place) {
      let city = "";
      let state = "";
      let zip = "";

      place?.address_components?.forEach((p) => {
        if (p?.types[0] === "locality") city = p?.short_name || "";
        if (p?.types[0] === "administrative_area_level_1")
          state = p?.short_name || "";
        if (p?.types[0] === "postal_code") zip = p?.short_name || "";
      });

      setData({
        ...data,
        street: place.formatted_address.split(",")[0],
        street2:
          place.formatted_address.split(",").length > 4
            ? place.formatted_address.split(",")[1]
            : "",
        city,
        state,
        zip,
      });
    }
  };
  const allStates =
    selectedCourier !== "usps" ? states : [...extraStates, ...states];

  //for phone number auto-generation
  const generatePhoneNo = () => {
    const num = Math.floor(9000000000 + Math.random() * 1000000000);
    setData({
      ...data,
      phone: num.toString(),
    });
  };

  useEffect(() => {
    const lines = pasteAddress.split("\n");

    // Extract and update the state with the parsed values
    if (lines.length === 3) {
      const [name, street, cityStateZip] = lines;

      const street1 = street.split(",")[0];
      const street2 = street.split(",")[1] || "";
      const lastTwoParts = cityStateZip?.split(" ")?.slice(-2);
      const [state, zip] = lastTwoParts;

      const city = cityStateZip?.replace(` ${state} ${zip}`, "");
      setData({
        name: name,
        street: street1,
        street2: street2,
        city: city,
        state: state,
        zip: zip,
        country: "US",
      });
    }
  }, [pasteAddress]);

  return (
    <Section
      title={
        <>
          {sender ? "Sender’s Details" : "Recipient’s Details"}

          <Typography display={"inline-block"} sx={{ ml: 2 }} fontWeight={500}>
            {["Name", "City", "State", "Zip"].every(
              (x) => data[x.toLowerCase()]
            ) ? (
              <>
                {["Name", "City", "State", "Zip"].map((info, i) =>
                  data[info.toLowerCase()] ? (
                    data[info.toLowerCase()] + (i !== 3 ? ", " : "")
                  ) : (
                    <Typography display={"inline"} color="error.main">
                      {info} missing{i !== 3 ? ", " : ""}
                    </Typography>
                  )
                )}
              </>
            ) : (
              <Chip label="DETAILS REQUIRED" sx={{ borderRadius: 1 }} />
            )}
          </Typography>
        </>
      }
      end={
        <Stack direction="row" spacing={1}>
          <Button
            onClick={openAddModal}
            sx={{ fontWeight: 600, fontSize: 14, gap: 0.5 }}>
            <AddRoundedIcon fontSize="small" /> Add New Address
          </Button>
          <IconButton type="button" onClick={() => setCollapsed(!collapsed)}>
            {collapsed ? (
              <KeyboardArrowDownRoundedIcon />
            ) : (
              <KeyboardArrowUpRoundedIcon />
            )}
          </IconButton>
        </Stack>
      }
      mb0
      sx={{ mb: 2 }}>
      {!collapsed && (
        <Grid container mt={2} spacing={2}>
          <Grid item xs={12}>
            <Stack direction="row" spacing={1}>
              <Button
                variant="outlined"
                onClick={() => setShowPaste(!showPaste)}
                sx={{ fontWeight: 600, fontSize: 14, gap: 0.5 }}>
                Paste Address
              </Button>
            </Stack>
          </Grid>
          {showPaste && (
            <Grid item xs={12}>
              <Field
                label="Paste Address (Paste According to placeholder add Comma(,)for address 2 & Avoid Extra Spaces)"
                placeholder={`Contact Name \nAddress 1 , Address 2(optional)\nCity State Zip`}
                value={pasteAddress}
                onChange={(e) => setPasteAddress(e.target.value)}
                multiline
                rows={3}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <CustomSelect
              label="Address Book Address"
              value={address._id}
              onChange={(e) => {
                setAddress(addresses.find((a) => a._id === e.target.value));
              }}
              options={addresses.map((address) => ({
                label: address.name,
                value: address._id,
              }))}
            />
          </Grid>
          <Grid item xs={12} container spacing={2}>
            <Grid item xs={6}>
              <Stack spacing={2}>
                <CustomSelect
                  label="Country*"
                  disabled
                  required
                  name={(sender ? "from" : "to") + "Country"}
                  value={
                    activeType?.uid === "ups_standard" ||
                    activeType?.uid === "ups_express" ||
                    activeType?.uid === "ups_express_saver" ||
                    activeType?.uid === "ups_express_early" ||
                    activeType?.uid === "ups_expedited"
                      ? "CA"
                      : "US"
                  }
                  options={[
                    {
                      label: "Canada",
                      value: "CA",
                    },
                    {
                      label: "United States",
                      value: "US",
                    },
                  ]}
                />

                {showAutocomplete ? (
                  <Autocomplete
                    onLoad={(ref) => (inputRef.current = ref)}
                    onPlaceChanged={handlePlaceChanged}
                    restrictions={{ country: "us" }}>
                    <Field
                      label="Address 1*"
                      placeholder="Enter Address 1"
                      value={data.street}
                      onChange={(e) =>
                        setData({
                          ...data,
                          street: e.target.value,
                        })
                      }
                      required
                    />
                  </Autocomplete>
                ) : (
                  <Field
                    label="Address 1*"
                    placeholder="Enter Address 1"
                    value={data.street}
                    onChange={(e) =>
                      setData({
                        ...data,
                        street: e.target.value,
                      })
                    }
                    required
                  />
                )}

                <Field
                  label="Zip * (auto-fills state and city)"
                  placeholder="Enter Zipcode"
                  required
                  value={data.zip}
                  onChange={(e) => {
                    setData({
                      ...data,
                      zip: e.target.value,
                    });
                    cityStateLookup(e.target.value, sender ? "from" : "to");
                  }}
                />
                <Field
                  label="City*"
                  placeholder="Enter City"
                  required
                  value={data.city}
                  onChange={(e) => {
                    setData({
                      ...data,
                      city: e.target.value,
                    });
                  }}
                />
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack spacing={2}>
                <Field
                  label="Contact Name*"
                  placeholder="Enter contact name"
                  value={data.name}
                  onChange={(e) => setData({ ...data, name: e.target.value })}
                  required
                />
                <Field
                  label="Address 2 (Optional)"
                  placeholder="Enter Address 2"
                  value={data.street2}
                  onChange={(e) => {
                    setData({
                      ...data,
                      street2: e.target.value,
                    });
                  }}
                />
                <Field
                  label="Company Name (Optional)"
                  placeholder="Enter Company name"
                  value={data.company}
                  onChange={(e) => {
                    setData({
                      ...data,
                      company: e.target.value,
                    });
                  }}
                />
                {selectedCourier === "ups" && (
                  <Field
                    label={
                      <Box sx={{ mb: -0.2 }}>
                        Phone*
                        <Button
                          sx={{ p: 0, ml: 1 }}
                          type="button"
                          onClick={generatePhoneNo}>
                          Auto-generate
                        </Button>
                      </Box>
                    }
                    placeholder="Enter phone number"
                    value={data.phone}
                    onChange={(e) => {
                      setData({
                        ...data,
                        phone: e.target.value,
                      });
                    }}
                  />
                )}
                {selectedCourier === "fedex" && (
                  <Field
                    label={
                      <Box sx={{ mb: -0.2 }}>
                        Phone*
                        <Button
                          sx={{ p: 0, ml: 1 }}
                          type="button"
                          onClick={generatePhoneNo}>
                          Auto-generate
                        </Button>
                      </Box>
                    }
                    placeholder="Enter phone number"
                    value={data.phone}
                    onChange={(e) => {
                      setData({
                        ...data,
                        phone: e.target.value,
                      });
                    }}
                  />
                )}

                <CustomSelect
                  label="State*"
                  value={data?.state || ""}
                  onChange={(e) => {
                    setData({
                      ...data,
                      state: e.target.value,
                    });
                  }}
                  options={allStates
                    .concat(
                      selectedCourier === "usps"
                        ? [
                            {
                              Country: "US",
                              ID: "VI",
                              Name: "U.S. Virgin Islands",
                            },
                            {
                              Country: "US",
                              ID: "GU",
                              Name: "Guam",
                            },
                          ]
                        : []
                    )
                    .filter((state) => state.Country === "US")
                    .map((state) => ({
                      label: state.Name,
                      value: state.ID,
                    }))}
                  required
                />
                {/* <Stack>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={saveAddressCheck}
                        onClick={() => setSaveAddressCheck(!saveAddressCheck)}
                      />
                    }
                    label="Save address to Address Book"
                  />
                </Stack> */}
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Section>
  );
};

const AddLabel = () => {
  const [couponVerified, setCouponVerified] = useState(false);
  const [coupon, setCoupon] = useState("");
  const [selectedCourier, setSelectedCourier] = useState(pricingItems[0].name);
  const navigate = useNavigate();
  const [addresses, setAddresses] = useState([]);
  const [address, setAddress] = useState({});
  const [address1, setAddress1] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);
  const [finalPrice, setFinalPrice] = useState(0);
  const [Type_id, setType_id] = useState("");
  const [Weight, setWeight] = useState(0);
  const [types, setTypes] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const signatureRequired = false;
  const scheduleEnabled = false;
  // eslint-disable-next-line no-unused-vars
  const [customItems, setCustomItems] = useState([
    {
      name: "",
      price: 0,
      quantity: 0,
    },
  ]);
  const [originalPrice, setOriginalPrice] = useState(0);
  const [activeType, setActiveType] = useState({});
  const [From, setFrom] = useState({
    name: "",
    street: "",
    street2: "",
    city: "",
    state: "",
    zip: "",
    company: "",
    country: "US",
  });
  const [To, setTo] = useState({
    name: "",
    street: "",
    street2: "",
    city: "",
    state: "",
    zip: "",
    company: "",
    country: "US",
  });

  const [addModal, setAddModal] = useState(false);

  const openAddModal = () => setAddModal(true);
  const [couponLoading, setCouponLoading] = useState(false);
  const verifyCoupon = async () => {
    if (!finalPrice) {
      toast.error("Please choose a carrier and set package weight first!");
      return;
    }
    setCouponLoading(true);
    await api
      .post("/coupon/validateCoupon", {
        price: finalPrice,
        coupon,
      })
      .then((res) => {
        setFinalPrice(res.data.finalDiscount);
        setCouponVerified(true);
      })
      .catch(() => {
        toast.error("Invalid Coupon!");
        setCouponVerified(false);
        getPrice();
      })
      .finally(() => setCouponLoading(false));
  };

  const getAddresses = async () => {
    await api
      .get("/address/readAll")
      .then((res) => {
        setAddresses(res.data.addresses);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const readLabelTypes = async () => {
    await api
      .get("/labeltype/read")
      .then((res) => {
        setTypes(res.data.labelTypes);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAddresses();
    readLabelTypes();
  }, []);

  useEffect(() => {
    const discountedPrice = activeType.discount / 100;
    const negativeDiscount = 1 - discountedPrice;
    const newPrice = finalPrice / negativeDiscount;
    setOriginalPrice(parseFloat(newPrice));
  }, [setFinalPrice, finalPrice, activeType]);

  useEffect(() => {
    setFrom({
      name: address.name,
      street: address.street,
      street2: address.street2,
      city: address.city,
      state: address.state,
      zip: address.zip,
      company: address.company,
      country: "US",
      phone: address.phone,
    });
  }, [address]);

  useEffect(() => {
    setTo({
      name: address1.name,
      street: address1.street,
      street2: address1.street2,
      city: address1.city,
      state: address1.state,
      zip: address1.zip,
      company: address1.company,
      country: "US",
      phone: address1.phone,
    });
  }, [address1]);

  const getPrice = async () => {
    const data = {
      Type: Type_id,
      Weight,
    };

    await api
      .post("/order/price", data)
      .then((res) => {
        setFinalPrice(res.data.price);
      })
      .catch((err) => {
        console.log(err);
        setFinalPrice(0);
      });
  };

  useEffect(() => {
    // find the type of label and max weight

    getPrice();
  }, [Type_id, Weight]);

  // on change custom items
  useEffect(() => {
    // add all customItems price
    let totalPrice = 0;
    // eslint-disable-next-line no-unused-vars
    let totalQuant = 0;
    customItems.forEach((item) => {
      totalPrice += parseInt(item.price);
      totalQuant += parseInt(item.quantity);
    });

    setTotalPrice(totalPrice);
  }, [customItems]);

  const { refresh } = useUserContext();

  const createOrder = async (e) => {
    e.preventDefault();

    if (
      parseFloat(e.target.Weight.value) < 0.0 ||
      parseFloat(e.target.Weight.value) > activeType?.maxWeight
    ) {
      toast.error("Invalid Weight");
      return;
    }

    setIsDisabled(true);

    const data = {
      price: finalPrice,
      Type: e.target.type.value,
      Weight: e.target.Weight.value,
      ToName: To.name,
      ToStreet: To.street,
      ToStreet2: To.street2 || "",
      ToCity: To.city,
      ToState: To.state,
      ToZip: To.zip,
      ToCompany: To.company || "",
      ToCountry: e.target.toCountry.value,
      FromName: From.name,
      FromStreet: From.street,
      FromStreet2: From.street2 || "",
      FromCity: From.city,
      FromState: From.state,
      FromZip: From.zip,
      FromCompany: From.company || "",
      FromCountry: e.target.fromCountry.value,
      height: e.target?.height?.value || 0,
      width: e.target?.width?.value || 0,
      length: e.target?.length?.value || 0,
      description: e.target.description?.value || "",
      // identifier: e.target.identifier?.value || "",
      reference1: e.target.reference1?.value || "",
      reference2: e.target.reference2?.value || "",
      FromPhone: From.phone,
      ToPhone: To.phone,

      // fedex int
      CustomsItems: customItems,
      CustomsPrice: totalPrice,
      ScheduleDate: e.target.scheduleDate?.value || "",
      SignatureRequired: signatureRequired,
      ScheduleEnabled: scheduleEnabled,
      Class: e.target.class?.value || "",
      coupon,
      couponVerified,
    };

    console.log(data);

    await api
      .post("/order/create", data)
      .then(async (res) => {
        toast.success("Order created successfully");
        refresh();
        if (saveFromAddress) saveToAddressBook(From);
        if (saveToAddress) saveToAddressBook(To);
        setIsDisabled(false);
        if (selectedCourier !== "fedex") {
          await downloadPdf(res.data?.order?._id);
        }
        navigate("/labels/" + res.data?.order?._id + "?new=true");
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message);
        setIsDisabled(false);
      });
  };
  const downloadPdf = async (id) => {
    await api
      .get(`/order/download/${id}`, env.downloadConfig)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "label.pdf");
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => console.log(err.response.data.message));
  };
  const theme = useTheme();

  //code for state city auto fill from zip
  const cityStateLookup = async (zip, type = "from") => {
    if (zip.length !== 5) return;

    await axios
      .get(`https://api.zippopotam.us/us/${zip.replaceAll("-", "")}`)
      .then((res) => {
        if (res.data) {
          if (type === "from")
            setFrom({
              ...From,
              city: res.data.places[0]["place name"],
              state: res.data.places[0]["state abbreviation"],
              zip: zip,
            });
          else
            setTo({
              ...To,
              city: res.data.places[0]["place name"],
              state: res.data.places[0]["state abbreviation"],
              zip: zip,
            });
        }
      })
      .catch((err) => console.log(err));
  };

  // code for save address
  const [saveFromAddress, setSaveFromAddress] = useState(false);
  const [saveToAddress, setSaveToAddress] = useState(false);

  const saveToAddressBook = async (data) => {
    const { company: _, ...body } = data;
    await api
      .post("/address/create", body)
      .then((res) => toast.success(res.data.message))
      .catch((err) => toast.error(err.response.data.message));
  };

  console.log(selectedCourier);

  return (
    <PageContainer
      title="Create Label"
      desc="Add the required details and get your label instantly"
      end={
        <PriceButton
          finalPrice={finalPrice}
          originalPrice={originalPrice}
          activeType={activeType}
        />
      }>
      {/* <LoadScript
        googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
        libraries={["places"]}
      > */}
      <form onSubmit={createOrder}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={7}>
            <Section sx={{ mb: 2 }}>
              <FormControl>
                <FormLabel sx={{ fontWeight: 600, mb: 2 }}>
                  Select Courier
                </FormLabel>
                <Grid container gap={2} mb={2}>
                  {pricingItems.map((p) => (
                    <OptionCard
                      imgSrc={
                        "/assets/images/" +
                        p.name +
                        (theme.palette.mode === "dark" ? "2" : "") +
                        ".svg"
                      }
                      name={p.name}
                      active={selectedCourier === p.name}
                      activate={() => setSelectedCourier(p.name)}
                    />
                  ))}
                </Grid>
              </FormControl>
            </Section>
            <AddressForm
              sender
              address={address}
              addresses={addresses}
              setAddress={setAddress}
              data={From}
              setData={setFrom}
              activeType={activeType}
              cityStateLookup={cityStateLookup}
              saveAddressCheck={saveFromAddress}
              setSaveAddressCheck={setSaveFromAddress}
              openAddModal={openAddModal}
              selectedCourier={selectedCourier}
            />
            <AddressForm
              address={address1}
              addresses={addresses}
              setAddress={setAddress1}
              data={To}
              setData={setTo}
              activeType={activeType}
              cityStateLookup={cityStateLookup}
              saveAddressCheck={saveToAddress}
              setSaveAddressCheck={setSaveToAddress}
              openAddModal={openAddModal}
              selectedCourier={selectedCourier}
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <Section title="Label details" sx={{ mb: 2 }}>
              <CustomSelect
                sx={{ mb: 2 }}
                label="Choose carrier"
                name="type"
                onChange={(e) => {
                  setType_id(e.target.value);
                  setActiveType(
                    types.find((type) => {
                      return type._id === e.target.value;
                    })
                  );
                }}
                options={types
                  .filter((type) =>
                    type.name.toLowerCase().includes(selectedCourier)
                  )
                  .map((type) => ({
                    label: type.name,
                    value: type._id,
                  }))}
              />
              {activeType?.banner && (
                <Alert sx={{ mb: 2 }} severity="info">
                  {activeType?.banner}
                </Alert>
              )}

              <Field
                sx={{ mb: 2 }}
                label="Weight*"
                name="Weight"
                placeholder={`Package Weight${
                  activeType?.maxWeight
                    ? " (" +
                      activeType?.maxWeight +
                      (activeType?.uid?.includes("firstclass")
                        ? " Oz"
                        : " Lbs") +
                      " Max)"
                    : ""
                }*`}
                type="text"
                pattern="[0-9]+([.][0-9]+)?"
                step="any"
                onChange={(e) => setWeight(e.target.value)}
              />
              {/* <Field
                sx={{ mb: 2 }}
                name="identifier"
                label="Identifier"
                placeholder="Enter an indentifier for the label"
              /> */}
              <Field
                sx={{ mb: 2 }}
                name="reference1"
                label="Reference 1"
                placeholder="Enter Reference 1"
              />
              <Field
                sx={{ mb: 2 }}
                name="reference2"
                label="Reference 2"
                placeholder="Enter Reference 2"
              />
              <Stack direction="row" alignItems="flex-end" spacing={1}>
                <Field
                  value={coupon}
                  onChange={(e) => setCoupon(e.target.value)}
                  name="coupon"
                  label="Coupon"
                  placeholder="Enter coupon code"
                />
                <Button
                  disabled={couponLoading}
                  type="button"
                  onClick={verifyCoupon}>
                  {couponLoading ? <Loader primary /> : "APPLY"}
                </Button>
              </Stack>
              {couponVerified && (
                <Chip
                  color="success"
                  sx={{ color: "white", mt: 2 }}
                  label={
                    "Coupon Applied! New Price is $" + finalPrice?.toFixed(2)
                  }
                />
              )}
            </Section>
            <Section title="Package Details" sx={{ mb: 2 }}>
              <Stack spacing={2}>
                {selectedCourier === "ups" && (
                  <FormControl fullWidth>
                    <FormLabel sx={{ fontWeight: 500, mb: 0.6 }}>
                      Dimensions*
                    </FormLabel>
                    <Stack direction="row" spacing={2}>
                      <Field placeholder="L" type="number" name="length" />
                      <Field placeholder="W" type="number" name="width" />
                      <Field placeholder="H" type="number" name="height" />
                    </Stack>
                  </FormControl>
                )}

                <Field
                  label="Description (Optional)"
                  placeholder="Enter description"
                  name="description"
                  multiline
                  rows={4}
                />
              </Stack>
            </Section>

            <Stack
              justifyContent="space-between"
              alignItems={"center"}
              spacing={2}>
              {/* <PriceButton
                  finalPrice={finalPrice}
                  originalPrice={originalPrice}
                  activeType={activeType}
                />{" "} */}
              <Button
                fullWidth
                disabled={!finalPrice || isDisabled}
                type="submit"
                variant="contained"
                sx={{ px: 5 }}
                size="large">
                {isDisabled ? (
                  <Loader />
                ) : (
                  "Create Label | $" + finalPrice.toFixed(2)
                )}
              </Button>
              <Typography variant="body2" color="text.secondary">
                Your purchase would be deducted from your Balance.
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </form>
      {/* </LoadScript> */}
      <AddAddress
        open={addModal}
        onClose={() => setAddModal(false)}
        address={{
          name: "",
          country: "",
          phone: "",
          street: "",
          street2: "",
          zip: "",
          city: "",
          state: "",
        }}
        edit={false}
        refresh={getAddresses}
      />
      {isDisabled ? <LinearBuffer /> : ""}
    </PageContainer>
  );
};

export default AddLabel;
